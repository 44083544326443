import * as Types from "../actions/actionTypes";

const initialState = {
  products: {
    loading: false,
    data: [],
    errors: {},
  },
  uploadProduct: {
    loading: false,
    data: {},
    success: false,
    errors: {},
  },
  deals: {
    loading: false,
    data: [],
    errors: {},
  },
  uploadDeal: {
    loading: false,
    data: {},
    success: false,
    errors: {},
  },
  procurements: {
    loading: false,
    data: [],
    errors: {},
  },
  uploadProcurement: {
    loading: false,
    data: {},
    success: false,
    errors: {},
  },
  events: {
    loading: false,
    data: [],
    errors: {},
  },
  liveStatus: {
    loading: false,
    data: [],
    errors: {},
  },
  inventoryForecast: {
    loading: false,
    data: [],
    errors: {},
  },
};

function uploadsReducers(state = initialState, action) {
  switch (action.type) {
    case Types.UPLOADED_PRODUCTS_LOADING: {
      return {
        ...state,
        products: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.UPLOADED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.UPLOADED_PRODUCTS_FAILURE: {
      return {
        ...state,
        products: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.UPLOAD_PRODUCT_LOADING: {
      return {
        ...state,
        uploadProduct: {
          loading: true,
          data: {},
          success: false,
          errors: {},
        },
      };
    }
    case Types.UPLOAD_PRODUCT_SUCCESS: {
      return {
        ...state,
        uploadProduct: {
          loading: false,
          data: action.payload,
          success: true,
          errors: {},
        },
      };
    }
    case Types.UPLOAD_PRODUCT_FAILURE: {
      return {
        ...state,
        uploadProduct: {
          loading: false,
          data: {},
          success: false,
          errors: action.payload,
        },
      };
    }
    case Types.UPLOADED_DEALS_LOADING: {
      return {
        ...state,
        deals: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.UPLOADED_DEALS_SUCCESS: {
      return {
        ...state,
        deals: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.UPLOADED_DEALS_FAILURE: {
      return {
        ...state,
        deals: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.UPLOAD_DEAL_LOADING: {
      return {
        ...state,
        uploadDeal: {
          loading: true,
          data: {},
          success: false,
          errors: {},
        },
      };
    }
    case Types.UPLOAD_DEAL_SUCCESS: {
      return {
        ...state,
        uploadDeal: {
          loading: false,
          data: action.payload,
          success: true,
          errors: {},
        },
      };
    }
    case Types.UPLOAD_DEAL_FAILURE: {
      return {
        ...state,
        uploadDeal: {
          loading: false,
          data: {},
          success: false,
          errors: action.payload,
        },
      };
    }
    case Types.UPLOADED_PROCUREMENTS_LOADING: {
      return {
        ...state,
        procurements: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.UPLOADED_PROCUREMENTS_SUCCESS: {
      return {
        ...state,
        procurements: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.UPLOADED_PROCUREMENTS_FAILURE: {
      return {
        ...state,
        procurements: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.UPLOAD_PROCUREMENT_LOADING: {
      return {
        ...state,
        uploadProcurement: {
          loading: true,
          data: {},
          success: false,
          errors: {},
        },
      };
    }
    case Types.UPLOAD_PROCUREMENT_SUCCESS: {
      return {
        ...state,
        uploadProcurement: {
          loading: false,
          data: action.payload,
          success: true,
          errors: {},
        },
      };
    }
    case Types.UPLOAD_PROCUREMENT_FAILURE: {
      return {
        ...state,
        uploadProcurement: {
          loading: false,
          data: {},
          success: false,
          errors: action.payload,
        },
      };
    }
    case Types.EVENTS_LOADING: {
      return {
        ...state,
        events: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.EVENTS_SUCCESS: {
      return {
        ...state,
        events: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.EVENTS_FAILURE: {
      return {
        ...state,
        events: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.LIVE_STATUS_LOADING: {
      return {
        ...state,
        liveStatus: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.LIVE_STATUS_SUCCESS: {
      return {
        ...state,
        liveStatus: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.LIVE_STATUS_FAILURE: {
      return {
        ...state,
        liveStatus: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.INVENTORY_FORECAST_LOADING: {
      return {
        ...state,
        inventoryForecast: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.INVENTORY_FORECAST_SUCCESS: {
      return {
        ...state,
        inventoryForecast: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.INVENTORY_FORECAST_FAILURE: {
      return {
        ...state,
        inventoryForecast: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.INVENTORY_FORECAST_REQUEST_LOADING: {
      return {
        ...state,
        inventoryForecast: {
          ...state.inventoryForecast,
          loading: true,
        },
      };
    }
    case Types.INVENTORY_FORECAST_REQUEST_SUCCESS: {
      return {
        ...state,
        inventoryForecast: {
          loading: false,
          data: [...(state?.inventoryForecast?.data || []), action.payload],
          errors: {},
        },
      };
    }
    default:
      return state;
  }
}

export default uploadsReducers;
